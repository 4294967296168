import React, { useState, useEffect } from 'react'
import { Queryparams, clrs } from '../Constants/Constants'
import { Box, Button, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Text, useToast } from '@chakra-ui/react'
import { FetchReportsDownline, FetchReportsDownlineDownload } from '../Queries/Queries'
import { genPDF_overall_downline, genPDF_pergame_downline } from '../Home/PdfMakeTable'
import ReportsPerGame from '../Home/ReportsPerGame'
import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import { GenExcel } from '../Home/GenExcel'

const ModalViewDownlinesPerGame = ({ isOpen, onClose, userdata, usertypedownline, reptypedownline, startDate, endDate, token, LoggingOut }) => {
    const [repdownline, setRepdownline] = useState([])
    const [page, setPage] = useState(1)
    const [search, setSearch] = useState('')
    const toast = useToast()

    const getReportsDownline = () => {
        setRepdownline([])
        FetchReportsDownline(page, token, reptypedownline, usertypedownline, startDate, endDate, search, userdata.username).then(res => {
            if (res.data) {
                if (res.err) {
                } else {
                    setRepdownline(res.data)
                }
            } else if (res.status === 401) {
                LoggingOut()
            } else {
                toast({
                    title: "FETCH REPORT ERROR!",
                    description: "hOoops! There is something wrong in fetching the reports.",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
            }
        }).catch((error) => {
            toast({
                title: "FETCH REPORT ERROR!",
                description: "Ooops! There is something wrong in fetching the reports.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
                variant: 'left-accent'
            })
        })
    }
    useEffect(() => {
        getReportsDownline()
    }, [page])

    const onClickDownload = () => {
        FetchReportsDownlineDownload(page, token, reptypedownline, usertypedownline, startDate, endDate, search, userdata.username).then(res => {
            if (res.data) {
                genPDF_pergame_downline(res.data, Queryparams.areanamepdf + " " + userdata.name + " GAME GROSS PER-GAME REPORT", startDate, endDate, userdata.name)
            } else if (res.status === 401) {
                LoggingOut()
            } else {
                toast({
                    title: "FETCH REPORT ERROR!",
                    description: "hOoops! There is something wrong in fetching the reports.",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
            }
        }).catch((error) => {
            toast({
                title: "FETCH REPORT ERROR!",
                description: "Ooops! There is something wrong in fetching the reports.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
                variant: 'left-accent'
            })
        })
    }

    const onClickDLExcel = async () => {

        FetchReportsDownlineDownload(page, token, reptypedownline, usertypedownline, startDate, endDate, search, userdata.username).then(res => {
            if (res.data) {
                GenExcel(userdata.username, res.data)
            } else if (res.status === 401) {
                LoggingOut()
            } else {
                toast({
                    title: "FETCH REPORT ERROR!",
                    description: "hOoops! There is something wrong in fetching the reports.",
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                    position: 'top',
                    variant: 'left-accent'
                })
            }
        }).catch((error) => {
            toast({
                title: "FETCH REPORT ERROR!",
                description: "Ooops! There is something wrong in fetching the reports.",
                status: 'error',
                duration: 3000,
                isClosable: true,
                position: 'top',
                variant: 'left-accent'
            })
        })
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'}>
            <ModalOverlay />
            <ModalContent minWidth="fit-content">
                <ModalHeader bg={clrs.txtinptbrdr} color={'white'}>{userdata.name} Per-Game downline reports</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                    <HStack justifyContent={"space-between"}>
                        <HStack>
                            <Input
                                color={'whiteAlpha.700'}
                                value={search}
                                bg={clrs.bglogin}
                                w={"120px"}
                                size="md"
                                borderWidth={0}
                                onChange={e => setSearch(e.target.value)}
                            />
                            <Button marginX={'5px'} onClick={() => getReportsDownline()}>
                                Search
                            </Button>
                        </HStack>
                        <Box display={"flex"} flexDir={"row"} p={2} justifyContent={'center'}>
                            <Button onClick={() => { page > 1 && setPage(page - 1) }}>{"<-"}</Button>
                            <Button w={50} marginInline={1} onClick={() => setPage(1)}>{page}</Button>
                            <Button onClick={() => setPage(page + 1)}>{"->"}</Button>
                        </Box>
                        <HStack>
                            <Button onClick={() => onClickDLExcel()}>
                                Excel
                            </Button>
                            <Button onClick={() => onClickDownload()}>
                                PDF
                            </Button>
                        </HStack>
                    </HStack>
                    <ReportsPerGame
                        reports={repdownline}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button
                        size={'md'}
                        colorScheme='red'
                        onClick={onClose}
                        w={'130px'}
                    >
                        Close
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal >
    )
}

export default ModalViewDownlinesPerGame