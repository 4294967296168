import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import { Queryparams } from "../Constants/Constants";

const transformData = (resp) => {
    const result = [];

    // Group data by gperiod
    const groupedByPeriod = resp.reduce((acc, user) => {
        user.per_game.forEach(game => {
            if (!acc[game.gperiod]) {
                acc[game.gperiod] = [];
            }
            const gnameKey = isNaN(game.gname.slice(-1)) ? game.gname : game.gname.slice(0, -1);
            acc[game.gperiod].push({
                username: user.username,
                name: user.name,
                [`${gnameKey}gross`]: game.gross,
                [`${gnameKey}hits`]: game.hits
            });
        });
        return acc;
    }, {});

    Object.keys(groupedByPeriod).forEach(gperiod => {
        const entries = [];
        const games = groupedByPeriod[gperiod];

        games.forEach(game => {
            const existingEntry = entries.find(entry => entry.username === game.username);
            if (existingEntry) {
                Object.assign(existingEntry, game);
            } else {
                entries.push(game);
            }
        });

        result.push({ gp: gperiod, ent: entries });
    });

    return result;
}

const generateFheader = (sourcefmt) => {
    const headersSet = new Set();

    sourcefmt.forEach(group => {
        group.ent.forEach(entry => {
            Object.keys(entry).forEach(key => {
                if (key !== 'username' && key !== 'name') {
                    headersSet.add(key);
                }
            });
        });
    });

    const headers = [
        { header: Queryparams.areanamepdf, key: 'name', width: 25 },
        { header: '', key: 'username', width: 15 }
    ];

    headersSet.forEach(key => {
        headers.push({ header: '', key, width: 12 });
    });

    return headers;
};

const convertToMftheader = (ent) => {
    const mftheader = {
        name: "Agent Name",
        username: "User Name",
    };

    ent.forEach(entry => {
        Object.keys(entry).forEach(key => {
            if (key !== 'username' && key !== 'name') {
                mftheader[key] = key;
            }
        });
    });

    return mftheader;
}

const desiredOrder = ["11AM", "2PM", "5PM", "9PM"];



const lets = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"];

export const GenExcel = async (uname, Resp) => {
    const Respv2 = transformData(Resp);
    const RespFinal = Respv2.sort((a, b) => {
        return desiredOrder.indexOf(a.gp) - desiredOrder.indexOf(b.gp);
    });

    const workbook = new Excel.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');
    const mainheader = generateFheader(Respv2)
    worksheet.columns = mainheader
    worksheet.addRow()
    const coor = worksheet.addRow({ name: "Coor Name: " + uname });
    worksheet.getCell('A' + coor.number).font = { bold: true }


    RespFinal.map(rp => {
        const drawrow = worksheet.addRow({ name: rp.gp + " DRAW" });
        drawrow.eachCell((cell) => {
            cell.font = { bold: true };
        });

        const hdr = worksheet.addRow(convertToMftheader(rp.ent));
        worksheet.mergeCells('A' + (hdr.number - 1) + ':' + lets[hdr.values.length - 2] + '' + (hdr.number - 1) + '');
        worksheet.getCell('A' + (hdr.number - 1)).fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6' }, }
        worksheet.getCell('A' + (hdr.number - 1)).border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
        hdr.eachCell((cell) => {
            cell.font = { bold: true };
            cell.alignment = { horizontal: 'center' }
            cell.border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
            cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6' }, }
        });

        rp.ent.map(rem => {
            const newRow = worksheet.addRow(rem);
            worksheet.getCell("A" + (newRow.number).toString()).border = {
                top: { style: 'thin' },
                left: { style: 'thin' },
                bottom: { style: 'thin' },
                right: { style: 'thin' }
            };

            const temp = Object.keys(rem).length
            for (let index = 0; index < temp; index++) {
                worksheet.getCell(lets[index] + (newRow.number).toString()).border = {
                    top: { style: 'thin' },
                    left: { style: 'thin' },
                    bottom: { style: 'thin' },
                    right: { style: 'thin' }
                };
            }
        })
        worksheet.addRow();
    })

    mainheader.map(hd => {
        if (hd.key.includes("gross")) {
            const G2DGross = worksheet.getColumn(hd.key);
            G2DGross.eachCell(cell => {
                cell.numFmt = '"₱" #,##0.00';
            })
        } else if (hd.key.includes("hits")) {
            const G2dHits = worksheet.getColumn(hd.key);
            G2dHits.eachCell(cell => {
                if (cell.value > 0) {
                    cell.font = { color: { argb: 'FFFF0000' } }
                    cell.numFmt = '"₱" #,##0.00';
                }

            })
        }
    })


    worksheet.mergeCells('A1:J1');
    worksheet.getCell("A1").border = { top: { style: 'thin' }, left: { style: 'thin' }, bottom: { style: 'thin' }, right: { style: 'thin' } };
    worksheet.getCell('A1').fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'ADD8E6' }, }
    worksheet.getCell('A1').font = { bold: true };
    worksheet.getCell('A1').alignment = { horizontal: 'center' }

    const buffer = await workbook.xlsx.writeBuffer();
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';

    const blob = new Blob([buffer], { type: fileType });
    // saveAs(blob, uname + " Per-Game Report " + fileExtension);
    saveAs(blob, uname + " Per-Game Report " + Resp[0].dt + fileExtension);
}